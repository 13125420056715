.site-about{
    .addmap{
        margin: 24px 0 0;
        iframe{
            width: 100%;
            height: auto;
            vertical-align: bottom;
            filter: grayscale(1);
            height: 280px;
            @media screen and (max-width:$responsive ){
                height: 200px;   
            }
        }
    }
}