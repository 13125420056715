.site-index{
    #firstview{
        width: 100%;
        height: 100vh;
        @include flex();
        align-items: center;
        position: relative;
        overflow: hidden;
        #slide{
            position: absolute;
            top: 0;
            left: 0;
			opacity: 0;
            z-index: -1;
			@include transition(800ms);
			&.swiper-container-initialized{
				opacity: 1;
			}
            .bg{
                width: 100vw;
                height: 100vh;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .swiper-slide-active .bg,
            .swiper-slide-duplicate-active .bg,
            .swiper-slide-prev .bg{
                animation: zoomUp 8s linear 0s 1 normal both;  
            }
            @keyframes zoomUp {
                0% {
                    transform: scale(1.08);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        .catch{
            position: relative;
            z-index: 2;
            color: #FFF;
            padding: 0 0 0 8%;
            dt{
                line-height: 1.16;
                font-size: calc(56px + 30 * (100vw - 450px)/ 900);
                font-family: 'Anton', sans-serif;
                @media screen and (max-width:$responsive){
                     font-size: calc(40px + 20 * (100vw - 450px)/ 900);
                }
            }
            dd{
                font-size: calc(18px + 6 * (100vw - 450px)/ 900);
                font-weight: 700;
                margin: 12px 0 0;
            }
        }
        .scroll{
            position: absolute;
            right: 40px;
            bottom: 40px;
            $size: 104px;
            width: $size;
            height: $size;
            padding: 16px;
            background: $blue;
            border-radius: 50%;
            box-shadow: 0 0 32px rgba(0,0,0,.32);
            @include flex();
            align-items: flex-end;
            justify-content: center;
            color: #FFF;
            font-size: 8px;
            letter-spacing: .12em;
            @media screen and (max-width:$responsive){
                font-size: 7px;
                width: 72px;   
                height:  72px;   
                right: 15px;
                bottom: 15px;
                padding: 12px 15px;
            }
            &:before{
                content: '';
                width: 23px;
                height: 44px;
                border: #FFF 1px solid;
                border-radius: 999px;
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                @media screen and (max-width:$responsive){
                    width: 16px;
                    height: 28px;
                    top: 15px;
                }
            }
            &:after{
                content: '';
                width: 1px;
                height: 30px;
                background: #FFF;
                position: absolute;
                top: 10px;
                left: 50%;
                animation: sc 2s infinite;
                @media screen and (max-width:$responsive){
                    height: 20px;
                    animation: scsp 2s infinite;
                }
                @keyframes sc {
                    0%{
                        height: 0;
                        top: 10px;
                    }
                    25%{
                        height: 30px;
                        top: 10px;
                    }
                    50%{
                        height: 0px;
                        top: 40px;
                    }
                    100%{
                        height: 0px;
                        top: 40px;
                    }
                }
                @keyframes scsp {
                    0%{
                        height: 0;
                        top: 10px;
                    }
                    25%{
                        height: 20px;
                        top: 10px;
                    }
                    50%{
                        height: 0px;
                        top: 30px;
                    }
                    100%{
                        height: 0px;
                        top: 30px;
                    }
                }
            }
        }
    }
	.news{
        position: relative;
        z-index: 2;
		background: $blue;
		color: #FFF;
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$responsive){
				width: 100%;
				padding: 0;
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width:$responsive){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 15px;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.2) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;;
					@media screen and (max-width:$responsive){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$responsive){
						float: left;
					}
                }
                time{
                    width: 14%;
                    font-size: 12px;
                    background: rgba(#000,.16);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$responsive){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$responsive){
						width: auto;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }
    .brand{
        .brandlist{
            @include flex();
            justify-content: flex-start;
            margin-left: -32px;
            @media screen and (max-width:$responsive){
                margin-left: -15px;   
            }
            li{
                list-style: none;
                width: 33.3%;
                padding-left: 32px;
                margin: 32px 0 0 0;
                @media screen and (min-width:$responsive + 1px){
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$responsive){
                    width: 50%;
                    margin: 15px 0 0;
                    padding-left: 15px;
                    &:nth-child(-n+2){
                        margin-top: 0;
                    }
                }
                figure{
                    border: $border 1px solid;
                    padding: 15px 40px;
                    text-align: center;
									height: 100%;
									@include flex();
									align-items: center;
									justify-content: center;
									line-height: 1.5;
                    @media screen and (max-width:$responsive){
                        padding: 8px 15px;   
                    }
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}