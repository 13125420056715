@mixin footerStyle(
	$footer-width:auto,
	$footer-height:auto,
	$footer-x:0,
	$footer-y:0,
	$footer-top-padding:0,
	$footer-right-padding:0,
	$footer-bottom-padding:0,
	$footer-left-padding:0,
	$footer-attachment:relative,
	$footer-nav-position:none,
	$footer-background:#FFF,
	$footer-zindex:1,
		
	$nav-class:'.footer-nav',
	$nav-position:none,
	$nav-top-padding:0,
	$nav-right-padding:0,
	$nav-bottom-padding:0,
	$nav-left-padding:0,
	
	$nav-list:none,
	$nav-list-side:0,
	$nav-list-top:0,
	
	$nav-list-border:'false',
	$nav-list-content:'',
	$nav-list-content-color:#000,
	
	$copy-class:'.copy',
	$copy-size:10px,
	$copy-color:#000,
	$copy-position:center,
	$copy-top-padding:0,
	$copy-right-padding:0,
	$copy-bottom-padding:0,
	$copy-left-padding:0,

	$responsive:'false',
	$responsive-radius:0,
	$responsive-color:$normal,
	$responsive-background:#FFF,
	$responsive-border:#EEE
){
	width:$footer-width;
	height:$footer-height;
	position:$footer-attachment;
	top:$footer-x;
	left:$footer-y;
	padding:$footer-top-padding $footer-right-padding $footer-bottom-padding $footer-right-padding;
	background:$footer-background;
	z-index:$footer-zindex;
	#{$nav-class}{
		@if $nav-position == center{
			text-align:center;
		}@else{
			float:$nav-position;
		}
		padding:$nav-top-padding $nav-right-padding $nav-bottom-padding $nav-left-padding;
		li{
			list-style:none;
			margin-top:$nav-list-top;			
			@if $nav-position == center{
				display:inline-block;
			}@else{
				float:$nav-list;
			}
			@if $nav-list == left{
				margin-left:$nav-list-side;
				@if $nav-list-border == 'true'{
					&:after{
						content:'#{$nav-list-content}';
						display:inline-block;
						margin-left:$nav-list-side;
						color:$nav-list-content-color;
					}
					&:last-child{
						&:after{
							display:none;
						}
					}
				}
				&:first-child{
					margin-left:0;	
				}
			}
			@if $nav-list == right{
				margin-right:$nav-list-side;
				&:before{
					content:'#{$nav-list-content}';
					display:inline-block;
					margin-right:$nav-list-side;
					color:$nav-list-content-color;
				}
				&:last-child{
					&:before{
						display:none;
					}
				}
				&:first-child{
					margin-right:0;	
				}
			}
		}
		@if $responsive == 'true'{
			border:$responsive-border 1px solid;
			border-radius:$responsive-radius;
			overflow:hidden;
			li{
				float:none;
				text-align:$nav-list;
				display:block;
				margin:0;
				padding:0;
				border-bottom:$responsive-border 1px solid;
				&:last-child{
					border:none;
				}
				a{
					background:$responsive-background;
					color:$responsive-color;
					display:block;
					padding:$nav-list-top $nav-list-side;
					&:hover{
						opacity:.9;
					}
				}
			}
		}
	}
	#{$copy-class}{
		display:block;
		font-size:$copy-size;
		color:$copy-color;
		padding:$copy-top-padding $copy-right-padding $copy-bottom-padding $copy-left-padding;
		@if $copy-position == center{
			text-align:center;
		}@else{
			float:$copy-position;
		}
	}
}


#site-footer{
	/* PC STYLE
	----------------------------------------*/
	@media screen and (min-width:769px){
		@include footerStyle(
			$footer-width:100%,
			$footer-height:auto,
			$footer-x:0,
			$footer-y:0,
			$footer-top-padding:72px,
			$footer-right-padding:30px,
			$footer-bottom-padding:72px,
			$footer-left-padding:30px,
			$footer-attachment:relative,
			$footer-nav-position:none,
			$footer-background:$blue,
			$footer-zindex:1,
			
			$nav-class:'.fnav',
			$nav-position:center,
			$nav-top-padding:0,
			$nav-right-padding:0,
			$nav-bottom-padding:0,
			$nav-left-padding:0,
			
			//$nav-listは$nav-positionがcenterの時は無効
			$nav-list:left,
			$nav-list-side:20px,
			$nav-list-top:0,
			$nav-list-border:'true',
			$nav-list-content:'|',
			$nav-list-content-color:rgba(#FFF,.12),
			
			$copy-class:'.copy',
			$copy-size:10px,
			$copy-color:#FFF,
			$copy-position:center,
			$copy-top-padding:32px,
			$copy-right-padding:0,
			$copy-bottom-padding:0,
			$copy-left-padding:0
		);
	}
	
	/* SP STYLE
	----------------------------------------*/
	@media screen and (max-width:768px){
		@include footerStyle(
			$footer-width:100%,
			$footer-height:auto,
			$footer-x:0,
			$footer-y:0,
			$footer-top-padding:30px,
			$footer-right-padding:12px,
			$footer-bottom-padding:30px,
			$footer-left-padding:12px,
			$footer-attachment:relative,
			$footer-nav-position:none,
			$footer-background:$blue,
			$footer-zindex:1,
			
			$nav-class:'.fnav',
			$nav-position:center,
			$nav-top-padding:0,
			$nav-right-padding:0,
			$nav-bottom-padding:0,
			$nav-left-padding:0,
			
			$nav-list:left,
			$nav-list-side:12px,
			$nav-list-top:12px,
			$nav-list-border:'false',
			$nav-list-content:'|',
			$nav-list-content-color:#000,
			
			$copy-class:'.copy',
			$copy-size:10px,
			$copy-color:#FFF,
			$copy-position:center,
			$copy-top-padding:20px,
			$copy-right-padding:0,
			$copy-bottom-padding:0,
			$copy-left-padding:0,
			
			$responsive:'true',
			$responsive-radius:6px,
			$responsive-color:$normal,
			$responsive-background:$blue,
			$responsive-border:rgba(#FFF,.16)
			
		);		
	}
	
	/* CUSTUM STYLE
	----------------------------------------*/
	a{
		color:#FFF;	
        @media screen and (max-width:$responsive){
            color: #FFF !important;
        }
	}
}