@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#0c101b;
$link:#0c3287;
$blue:#0c3287;
$border:#DDD;

$glay: #f2f2f2;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:72px;
$sp-head:60px;

$responsive: 768px;

@media screen and (min-width:$responsive + 1px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/news";
@import "page/about";
@import "page/contact";

#site-wrapper,#site-footer{
    position: relative;
    top: 0;
}

.site-module{
    &.wrap{
        padding: 72px 0; 
        @media screen and (max-width:$responsive){
            padding: 32px 15px;
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
    }
    h2.large{
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 32px;
        @media screen and (max-width:$responsive){
            font-size: 14px;
            margin: 0 0 24px;
        }
        &:before{
            content: attr(data-eng);
            font-size: 40px;
            letter-spacing: .12em;
            line-height: 1;
            display: block;
            margin: 0 0 6px;
            @media screen and (max-width:$responsive){
                font-size: 32px;
                margin: 0 0 2px;
            }
        }
    }
    .table-style{
        border: none;
        border-collapse: collapse;
        width: 100%;
        @media screen and (max-width:$responsive ){
            display: block;
            border: $border 1px solid;
            tbody,
            tr,
            th,
            td{
                display: block;
            }
        }
        tbody{
            tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive ){
                    &:last-child{
                        border-bottom: none;
                    }   
                }
                th,td{
                    text-align: left;
                    padding: 32px 0;
                    font-size: 15px;
                    @media screen and (max-width:$responsive ){
                        padding: 15px;
                        font-size: 14px;
                    }
                }
                th{
                    width: 200px;
                    @media screen and (max-width:$responsive ){
                        width: auto;
                        background: $glay;
                    }
                }
                td{
                    
                }
            }
        }
    }
}

@media screen and (max-width:$responsive){
    .scale-enter-active, .scale-leave-active {
        transition: 600ms;
    }
    .scale-enter, .scale-leave-to {
        opacity: 0;
        transform: scale(1.1);
    }
}