.site-news{
    .title{
        line-height: 1;
        margin: 0 0 20px;
        font-weight: 700;
        time{
            display: block;
            font-size: 12px;
            margin: 0 0 12px;
            color: $blue;
        }
        h2{
            font-size: 24px;
            font-weight: 700;
        }
    }
    .content{
        font-size: 15px;
        a{
            text-decoration: underline;
            color: $blue;
            font-weight: 700;
        }
    }
}