@charset "utf-8";

#site-header{
    position: absolute;
    width: 100%;
    padding: 0 48px;
    padding-right: 0;
    top: 0;
    left: 0;
    z-index: 1000;
    @media screen and (max-width:$responsive){
        padding: 15px;
    }
    .display{
        width: auto;
        @include flex();
        align-items: center;
    }
    .logo{
        width: 164px;
        @media screen and (max-width:$responsive){
            width: 132px;   
        }
        img{
            width: 100%;
        }
    }
    .gnavi{
		@media screen and (max-width:$responsive ){
			$size: 40px;
			&:before{
				content: '';
				float: none;
				position: fixed;
				top: 15px;
				right: 15px;
				width: $size;
				height: $size;
				border-radius: 50%;
				background: $blue;
				border: $blue 1px solid;;
				z-index: 1000;
				cursor: pointer;
				@include transition(400ms);
			}
			&:after{
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                content: "\f0c9";
				position: fixed;
				top: 24px;
				right: 29px;
				z-index: 1001;
				color: #FFF;
				font-size: 13px;
				cursor: pointer;
			}
			&.active{
				&:before{
					border: #FFF 1px solid;
					transform: scale(1.04);
				}
				&:after{
					content: "\f00d";
					right: 30px;
				}
            }
		}  
        ul{
            @include flex();
            align-items: center;
            background: #FFF;
            padding: 0 0 0 56px;
			@media screen and (min-width:$responsive + 1px){
				display: flex!important;
			}
			@media screen and (max-width:$responsive ){
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $blue;
				@include flex();
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 40% 0;
				z-index: 500;
				&:after{
					display: none;
				}
			}
            li{
                list-style: none;
                font-size: 14.5px;
                margin: 0 0 0 56px;
				@media screen and (max-width:$responsive ){
					float: none;
					width: 100%;
					margin: 0;
				}
                a{
                    color: $normal;
                    font-weight: 600;
                    letter-spacing: .012em;
                    &:hover{
                        color: $blue;
                    }
                    @media screen and (max-width:$responsive ){
                        color: #FFF;
                        &:hover{
                            color: #FFF;
                        }
                    }
                }
                &:first-child,
                &:last-child{
                    margin: 0;
                }
                &.contact{
                    $size: $pc-head;
                    margin: 0 0 0 56px;
                    @media screen and (max-width:$responsive ){
                        margin: 0;   
                    }
                    i{
                        display: inline-block;
                        margin: 0 12px 0 0;
                        @media screen and (max-width:$responsive ){
                            display: none;
                        }
                    }
                    a{
                        @media screen and (min-width:$responsive + 1px){
                            height: $size;
                            padding: 0 32px;
                            color: #FFF;
                            @include flex();
                            align-items: center;
                            background: $blue;
                            &:hover{
                                background: lighten($blue,8%);
                            }
                        }
                    }
                }
            }
        }
    }
}