.site-contact{
    .table-style{
        &.form-style{
            tbody{
                tr{
                    td,th{
                        vertical-align: middle;
                    }
                    th{
                        width: 240px;
                        position: relative;
                        @media screen and (max-width:$responsive ){
                            width: auto;   
                        }
                        &:after{
                            content: '任意';
                            background: $normal;
                            color: #FFF;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 1px 0;
                            width: 64px;
                            border-radius: 3px;
                            text-align: center;
                            transform: translateY(-50%);
                        }
                        &.hiss{
                            &:after{
                                content: '必須';
                                background: $blue;
                            }
                        }
                    }
                    td{
                        padding-left: 48px;
                        @media screen and (max-width:$responsive ){
                            padding-left: 15px;
                        }
                        input[type=text],
                        input[type=email],
                        input[type=tel],
                        textarea{
                            font-size: 15px;
                            width: 100%;
                            @include font();
                            border: $border 1px solid;
                            padding: 8px;
                            border-radius: 3px;
                            @include transition(200ms);
                            &:focus {
                                border-color: #FFF;
                                box-shadow: 0 8px 48px rgba(#000,.16);
                            }
                        }
                        textarea{
                            height: 200px;
                        }
                    }
                }
            }
        }
    }
    .btnarea{
        text-align: center;
        margin: 32px 0 0;
        input{
            @include font();
            font-size: 20px;
            font-weight: 700;
            width: 240px;
            padding: 12px;
            background: $blue;
            color: #FFF;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            &:hover{
                background: lighten($blue,8%);
            }
        }
    }
}